<template>
    <div>
        <Header>
            <template v-slot:left></template>
            <template v-slot:mid>
                关于itrax
            </template>
        </Header>
        <div class="col-wrap ">
            <img src="@/assets/mobile/intro/banner-bg.jpg" />
            <div class="mask banner">
                <h1>ABOUT ITRAX</h1>
                <h3>让品牌有情怀，让营销有温度</h3>
                <p>Considerate Branding for Effective Marketing</p>
            </div>
        </div>
        <div class="col-wrap intro">
            <img class="tit" src="@/assets/mobile/intro/intro-tit.png" />
            <div class="content summary-content" v-html="about.summary"></div>

            <div class="blue-btn" @click="showQr = true">关注爱创公众号</div>
            <div>
                <img class="dw-tit" src="@/assets/mobile/intro/dw-tit.png" />
            </div>
            <div class="dw content">
                <img :src="resourceBaseUrl + about.positionMobile" />
            </div>
            <div>
                <img class="honor-tit" src="@/assets/mobile/intro/honor-tit.png" />
            </div>
            <ul class="honor">
                <li v-for="item in honor" :key="item.id">
                    <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,h_500,w_500'" />
                </li>
            </ul>
        </div>
        <Footer></Footer>
        <div class="qr_mask" v-if="showQr" @click="showQr = false" @touchmove.prevent>
            <img src="@/assets/mobile/intro/qr.jpg" v-on:click.stop="" />
            <span>长按识别二维码，关注爱创公众号</span>
        </div>
    </div>
</template>
<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import settings from "@/settings";
export default {
    name: "mobile-intro",
    components: { Header, Footer },
    data() {
        return { resourceBaseUrl: settings.resourceBaseUrl, honor: [], showQr: false, about: {} };
    },
    mounted() {
        document.addEventListener("touchstart", function() {}, false);
        this.loadHonor();
        this.loadSummary();
    },
    methods: {
        loadSummary() {
            this.$http.get("/api/about/summary").then((res) => {
                this.about = res.data;
            });
        },
        loadHonor() {
            this.$http.get("/api/honor/list").then((res) => {
                this.honor = res.data;
            });
        },
    },
};
</script>

<style scoped>
@import url("../../../common/css/mobile.css");
.summary-content {
    color: #333;
    line-height: 25px;
}
.qr_mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.qr_mask img {
    width: 70%;
    border-radius: 10px;
}
.qr_mask span {
    font-size: 16px;
    margin: 15px 0;
}
.honor-tit {
    width: 87%;
    margin: 30px 0 20px 0;
}
.honor {
    margin: 0 20px 40px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
}
.honor li {
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    overflow: hidden;
    border: 1px #eeeeee solid;
}
.honor li img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.honor li:hover img {
    transform: scale(1.1);
}

.dw p {
    text-indent: 0 !important;
}
.dw {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dw img {
    width: 80%;
    margin: 10px 0 25px 0;
}
.dw-tit {
    width: 87%;
    margin: 50px 0 30px 0;
}
.intro .content {
    margin: 0px 15px 30px 15px;
    text-align: left;
}
.intro .content p {
    color: #333;
    margin: 0 0 6px 0;
    text-indent: 26px;
    font-size: 13px;
    line-height: 22px;
}
.intro .content p b {
    color: #333;
}
.intro .tit {
    margin: 20px 10%;
    width: 75%;
}
.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner h1 {
    font-size: 20px;
}
.banner h3 {
    font-size: 15px;
    margin: 20px 0;
}
.banner p {
    font-size: 12px;
}
</style>
